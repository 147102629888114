import React from "react"
import Layout from "../components/layout"
import Carousel from "react-bootstrap/Carousel"
import LogoSVG from "../assets/Logo.svg"
import slide01 from "../assets/002_Lang.jpg"
import slide02 from "../assets/004_Lang.jpg"
import slide03 from "../assets/006_Lang.jpg"
import slide04 from "../assets/001_Lang_reduced.jpg"
import slide05 from "../assets/003_Lang.jpg"

const IndexPage = () => (
    <Layout title='Home'>
      <div id='bglogoHome'>
        <img className='img-fluid' src={LogoSVG} width='800' height='600' alt='Background Logo'/>
      </div>
      <Carousel>
        {
          [
            slide01,
            slide02,
            slide03,
            slide04,
            slide05
          ].map((slide, idx) => createSlideShowItem(slide, `slide-${idx}`))
        }
      </Carousel>
    </Layout>
)

function createSlideShowItem(image, altText) {
  return (
    <Carousel.Item key={altText}>
      <img
        className="d-block w-100"
        src={image}
        alt={altText}
      />
    </Carousel.Item>
  )
}

export default IndexPage
